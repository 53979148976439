/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  message,
} from "antd";
import type { UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import dayjs from "dayjs";
import "./ManualNotificationDetail.css";
import { data } from "../../../../data/data";
import { ServiceTypes } from "../../../../types/types";
import MainContentHeader from "../../../../components/layout/mainContent/MainContentHeader";
import ContentSection from "../../../../components/layout/mainContent/ContentSection";
import MainContentFooter from "../../../../components/layout/mainContent/MainContentFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import { __currentUser__ } from "../../../../graphql/policies";
import { useAddNotificationMutation } from "../../../../graphql/operations/add-notification";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import { useGetNotificationLazyQuery } from "../../../../graphql/operations/get-notification";
import Spinner from "../../../../components/layout/spinner/Spinner";
import { renderMarketValue } from "../../../../utils/renderMarketValue";
// import { useUpdateNotificationMutation } from "../../../../graphql/operations/update-notification";
import { useGetBlobSasQuery } from "../../../../graphql/operations/get-blob-info";
import { renderServiceTypeName } from "../../../../utils/renderServiceTypeName";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";
import imageValidation from "../../../../utils/formValidation/imageValidation";
import requiredRule from "../../../../utils/formRules/requiredRule";

const { notificationActionTypes, allButtonLinksTo } = data;
const graduationActionTypes = [
  {
    id: 1,
    typeName: "None",
    typeValue: "none",
  },
  {
    id: 2,
    typeName: "Graduated collection page",
    typeValue: "Graduated",
  },
];
const { TextArea } = Input;

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

interface ManualNotificationDetailProps {
  setSelectedService: Dispatch<SetStateAction<ServiceTypes>>;
  setSelectSubItem: Dispatch<SetStateAction<string | undefined>>;
}

const ManualNotificationDetail = ({
  setSelectedService,
  setSelectSubItem,
}: ManualNotificationDetailProps) => {
  const currentUser = useReactiveVar(__currentUser__);
  const [selectDetail, setSelectDetail] = useState<any>();
  const [selectActionType, setSelectActionType] = useState<
    string | undefined
  >();
  const [uploadedFileName, setUploadedFileName] = useState<any>();
  const [testId, setTestId] = useState<string | undefined>("");
  const [serviceTypeId, setServiceTypeId] = useState("");
  const { id }: any = useParams();
  const [settingsForm] = Form.useForm();
  const [addNotification, { loading }] = useAddNotificationMutation();
  const [addTestNotification, { loading: testLoading }] =
    useAddNotificationMutation();
  const [uploadFile] = useUploadFileMutation();
  const navigate = useNavigate();
  const [getNotification, { loading: fetchLoading, error }] =
    useGetNotificationLazyQuery({
      variables: { id: parseInt(id) },
      fetchPolicy: "no-cache",
    });
  // const [updateNotification, { loading: updateLoading }] =
  //   useUpdateNotificationMutation();
  // const [updateTestNotification, { loading: updateTestLoading }] =
  //   useUpdateNotificationMutation();
  const { data, loading: blobLoading, error: blobError } = useGetBlobSasQuery();
  const messageRef = useRef<any>(null);

  const props: UploadProps = {
    beforeUpload: imageValidation(100, "KB"),
    onChange: (info) => {
      console.log(info);
      if (info.fileList.length === 0) {
        setUploadedFileName(undefined);
        return;
      }
      setUploadedFileName(info.file.name);
      uploadFile({
        variables: {
          blobs: [
            {
              file: info.file.originFileObj,
              path: AfBlobPath.NotificationImage,
            },
          ],
        },
      }).catch((e) => console.log(e));
    },
  };

  const handleSubmit = (values: any) => {
    let memberIds;
    if (values.members) {
      memberIds = [{ memberNo: values.members }];
    }
    const formData = {
      actionButton: values.actionButton,
      serviceTypeId: values.serviceTypeId,
      title: memberIds ? `[TEST]${values.title}` : values.title,
      templateMessage: values.templateMessage,
      creator: currentUser?.userName,
      scheduleTime: values.scheduleTime
        ? dayjs(values.scheduleTime).toISOString()
        : undefined,
      buttonLinksTo:
        selectActionType === "AppEmbedURL" || selectActionType === "OpenBrowser"
          ? `https://${values.buttonLinksTo}`
          : values.buttonLinksTo,
      imageFileName: uploadedFileName ? uploadedFileName : undefined,
      notificationId: id ? parseInt(id) : null,
      market: values.market
        ? values.market
        : currentUser &&
          currentUser.afUserMarkets &&
          renderMarketValue(currentUser.afUserMarkets[0]?.market),
      buttonText: values.buttonText,
    };
    console.log(formData);
    if (memberIds) {
      console.log("test");
      if (id) {
        // updateTestNotification({
        //   variables: {
        //     notification: formData,
        //     members: memberIds,
        //     isAllMembers: false,
        //   },
        // })
        //   .then(() => {
        //     settingsForm.setFieldValue("members", "");
        //     setTestId("");
        //     if (values.scheduleTime) {
        //       message.success("Scheduled Notification has been submitted.");
        //     } else {
        //       message.success(
        //         "Notification has been submitted. This can sometimes take a few minutes to arrive."
        //       );
        //     }
        //   })
        //   .catch((e) => console.log(e));
      } else {
        addTestNotification({
          variables: {
            notification: formData,
            members: memberIds,
            isAllMembers: false,
          },
        })
          .then(() => {
            settingsForm.setFieldValue("members", "");
            setTestId("");
            if (values.scheduleTime) {
              message.success(
                renderAntDMessageConfig(
                  "Scheduled Notification has been submitted."
                )
              );
            } else {
              message.success(
                renderAntDMessageConfig(
                  "Notification has been submitted. This can sometimes take a few minutes to arrive."
                )
              );
            }
          })
          .catch((e) => console.log(e));
      }
    } else {
      console.log("all members");
      if (id) {
        // updateNotification({
        //   variables: {
        //     notification: formData,
        //     members: null,
        //     isAllMembers: true,
        //   },
        // })
        //   .then(() => {
        //     if (values.scheduleTime) {
        //       message.success("Scheduled Notification has been submitted.");
        //     } else {
        //       message.success(
        //         "Notification has been submitted. This can sometimes take a few minutes to arrive."
        //       );
        //     }
        //     navigate("/club-app/push-notification");
        //   })
        //   .catch((e) => console.log(e));
      } else {
        addNotification({
          variables: {
            notification: formData,
            members: null,
            isAllMembers: true,
          },
        })
          .then(() => {
            if (values.scheduleTime) {
              message.success(
                renderAntDMessageConfig(
                  "Scheduled Notification has been submitted."
                )
              );
            } else {
              message.success(
                renderAntDMessageConfig(
                  "Notification has been submitted. This can sometimes take a few minutes to arrive."
                )
              );
            }
            navigate("/club-app/push-notification");
          })
          .catch((e) => console.log(e));
      }
    }
  };

  const handleRePush = () => {
    const formData: any = {
      actionButton: selectDetail.actionButton,
      serviceTypeId: selectDetail.afNotificationServiceTypeId,
      title: selectDetail.title,
      templateMessage: selectDetail.templateMessage,
      creator: selectDetail.creator,
      scheduleTime: null,
      buttonLinksTo: selectDetail.buttonLinksTo,
      imageFileName: selectDetail.imageFileName,
      notificationId: null,
      market: renderMarketValue(selectDetail.market),
      buttonText: selectDetail.buttonText,
    };
    console.log(formData);
    addNotification({
      variables: {
        notification: formData,
        members: null,
        isAllMembers: true,
      },
    })
      .then(() => {
        message.success(
          "Notification has been submitted. This can sometimes take a few minutes to arrive."
        );
        navigate("/club-app/push-notification");
      })
      .catch((e) => console.log(e));
  };

  const renderActionType = (type: string) => {
    switch (type) {
      case "none":
        return "None";
      case "AppScreen":
        return "Club app screen";
      case "AppEmbedURL":
        return "Embed URL";
      case "OpenBrowser":
        return "Open browser";
      default:
        break;
    }
  };

  useEffect(() => {
    setSelectedService(ServiceTypes.CLUB_APP);
    setSelectSubItem("Push Notification");
  }, []);

  useEffect(() => {
    if (id) {
      getNotification()
        .then((r) => {
          if (r.data?.wf_adminFirst_afNotification?.edges) {
            setSelectDetail(
              r.data?.wf_adminFirst_afNotification?.edges[0].node
            );
          }
        })
        .catch((e) => console.log(e));
    }
  }, [id]);

  useEffect(() => {
    settingsForm.setFieldValue("actionButton", "none");
    settingsForm.setFieldValue("market", "HONG_KONG");
    if (
      currentUser &&
      currentUser.afUserMarkets &&
      currentUser.afUserMarkets.length === 1
    ) {
      settingsForm.setFieldsValue({
        market: renderMarketValue(currentUser.afUserMarkets[0]?.market),
      });
    }
  }, [currentUser]);

  if (error || blobError) {
    message.error(
      renderAntDMessageConfig(
        "Something went wrong. Please contact your system administrator."
      )
    );
  }

  if (fetchLoading || blobLoading) {
    return <Spinner />;
  }

  return (
    <div style={{ position: "relative", paddingBottom: 48 }}>
      <MainContentHeader
        title={
          selectDetail &&
          (selectDetail.scheduleTime <= dayjs().toISOString() ||
            !selectDetail.scheduleTime)
            ? "View notification detail"
            : "Notification detail"
        }
      />
      <ContentSection title="" showTitle={false}>
        {selectDetail ? (
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            className="none-edit"
          >
            {currentUser &&
              currentUser.afUserMarkets &&
              currentUser.afUserMarkets.length > 1 && (
                <Form.Item label="Market">
                  <p>{selectDetail.market}</p>
                </Form.Item>
              )}
            <Form.Item label="Service type">
              <p>
                {renderServiceTypeName(
                  selectDetail.afNotificationServiceTypeId
                )}
              </p>
            </Form.Item>
            <Form.Item label="Title">
              <p>{selectDetail.title}</p>
            </Form.Item>
            <Form.Item label="Message">
              <p
                ref={messageRef}
                style={{
                  width: 480,
                  lineHeight: "22px",
                  paddingTop: messageRef?.current?.clientHeight > 30 ? 5 : 0,
                }}
              >
                {selectDetail.templateMessage}
              </p>
            </Form.Item>
            {selectDetail.imageFileName && (
              <Form.Item label="Uploaded image">
                <div style={{ width: "100px" }}>
                  <img
                    src={`${data?.afBlobContainerSignature[3].uri}/${selectDetail.imageFileName}${data?.afBlobContainerSignature[3].token}`}
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </Form.Item>
            )}
            <Form.Item label="Action button">
              <p>{renderActionType(selectDetail.actionButton)}</p>
            </Form.Item>
            {selectDetail.buttonLinksTo && (
              <Form.Item label="Button links to">
                <p>{selectDetail.buttonLinksTo}</p>
              </Form.Item>
            )}
            {selectDetail.buttonText && (
              <Form.Item label="Button text">
                <p>{selectDetail.buttonText}</p>
              </Form.Item>
            )}
            <Form.Item label="Push date and time">
              <p>
                {selectDetail.scheduleTime
                  ? dayjs(selectDetail.scheduleTime).format("YYYY-MM-DD HH:mm")
                  : dayjs(selectDetail.createdTime).format("YYYY-MM-DD HH:mm")}
              </p>
            </Form.Item>
            <MainContentFooter
              isLoading={loading}
              backTo="/club-app/push-notification"
              isOneButton
              handleClick={handleRePush}
            />
          </Form>
        ) : (
          <Form
            form={settingsForm}
            name="settings"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            onValuesChange={(changedValues) => {
              if (changedValues.serviceTypeId) {
                setServiceTypeId(changedValues.serviceTypeId);
              }
            }}
            onFinish={handleSubmit}
          >
            {currentUser &&
              currentUser.afUserMarkets &&
              currentUser.afUserMarkets.length > 1 && (
                <Form.Item
                  label="Market"
                  name="market"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Radio.Group
                    options={[
                      { label: "HK", value: "HONG_KONG" },
                      { label: "KR", value: "KOREA" },
                      { label: "TW", value: "TAIWAN" },
                    ]}
                    optionType="button"
                  />
                </Form.Item>
              )}
            <Form.Item
              label="Service Type"
              name="serviceTypeId"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                options={[
                  { label: "Club News", value: "0001" },
                  { label: "Go, Go Live!", value: "0002" },
                  { label: "Graduation", value: "0003" },
                ]}
              />
            </Form.Item>
            {serviceTypeId === "0003" && (
              <Form.Item
                label="Recipient"
                name="recipient"
                rules={[requiredRule]}
              >
                <Radio.Group
                  options={[
                    { label: "This year Graduates", value: "Graduate" },
                    { label: "This year Cambridge kids", value: "Cambridge" },
                  ]}
                  optionType="button"
                />
              </Form.Item>
            )}
            <Form.Item label="Recipient's member No." name="recipientNo">
              <InputCustom placeholder="Enter the member number(s), separated by commas." />
            </Form.Item>
            <Form.Item label="Title" name="title">
              <InputCustom placeholder="Title shown on the screen" />
            </Form.Item>
            {serviceTypeId === "0003" && (
              <Form.Item
                wrapperCol={{ offset: 8, span: 12 }}
                style={{ marginTop: "-24px" }}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  If you want to display the child's name in the sentence,
                  please type in {`{ChildName}`}
                </span>
              </Form.Item>
            )}
            <Form.Item
              label="Message"
              name="templateMessage"
              rules={[requiredRule]}
            >
              <TextArea
                showCount
                maxLength={300}
                style={{ height: 54 }}
                placeholder="Autosize height based on content lines
"
              />
            </Form.Item>
            {serviceTypeId === "0003" && (
              <Form.Item
                wrapperCol={{ offset: 8, span: 12 }}
                style={{ marginTop: "-16px" }}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  If you want to display the child's name in the sentence,
                  please type in {`{ChildName}`}
                </span>
              </Form.Item>
            )}
            <Form.Item
              label="Image(optional)"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                {...props}
                action="https://httpbin.org/post"
                listType="picture-card"
                maxCount={1}
              >
                <div style={{ position: "relative" }}>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                  <ul
                    style={{
                      position: "absolute",
                      left: "120px",
                      top: "-24px",
                      width: 300,
                      textAlign: "left",
                      color: "rgba(0, 0, 0, 0.45)",
                      listStyle: "disc",
                    }}
                  >
                    <li>Max file size: 100KB</li>
                    <li>Recommended width: 600px</li>
                    {serviceTypeId === "0003" ? (
                      <li>
                        Get the Graduation banner from:&nbsp;
                        <a
                          href=""
                          className="link"
                          target="_blank"
                          rel="noopener noreferrer"
                        ></a>
                      </li>
                    ) : (
                      <li>
                        Get the monthly Face Call banner from:&nbsp;
                        <a
                          href="https://wfeinnovation.atlassian.net/wiki/x/RwAKWQ"
                          className="link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://wfeinnovation.atlassian.net/wiki/x/RwAKWQ
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </Upload>
            </Form.Item>
            {selectDetail && selectDetail.imageFileName && (
              <Form.Item label="Uploaded image">
                <div style={{ width: "100px" }}>
                  <img
                    src={`${data?.afBlobContainerSignature[3].uri}/${selectDetail.imageFileName}${data?.afBlobContainerSignature[3].token}`}
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </Form.Item>
            )}
            <Form.Item
              label="Action button"
              name="actionButton"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Radio.Group
                options={
                  serviceTypeId !== "0003"
                    ? notificationActionTypes.map((type) => ({
                        label: type.typeName,
                        value: type.typeValue,
                      }))
                    : graduationActionTypes.map((type) => ({
                        label: type.typeName,
                        value: type.typeValue,
                      }))
                }
                optionType="button"
                onChange={(e) => {
                  setSelectActionType(e.target.value);
                  settingsForm.setFieldsValue({
                    buttonLinksTo: "",
                    buttonText: "",
                  });
                }}
              />
            </Form.Item>
            {selectActionType && selectActionType !== "none" && (
              <>
                {serviceTypeId !== "0003" ? (
                  selectActionType === "AppScreen" ? (
                    <Form.Item label="Button links to" name="buttonLinksTo">
                      <Select placeholder="- Select -">
                        {allButtonLinksTo.map((item) => (
                          <Select.Option key={item.id} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item label="Button links to" name="buttonLinksTo">
                      <InputCustom addonBefore="https://" />
                    </Form.Item>
                  )
                ) : null}
                <Form.Item
                  label="Button text"
                  name="buttonText"
                  rules={[requiredRule]}
                >
                  <InputCustom />
                </Form.Item>
              </>
            )}
            <Form.Item label="Push date and time" name="scheduleTime">
              <DatePicker
                showTime
                style={{ width: "100%" }}
                placeholder="Select date and time"
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 8, span: 12 }}
              style={{ marginTop: "-24px" }}
            >
              <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                Send it immediately when not select date and time
              </span>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
              <Divider />
            </Form.Item>
            <Form.Item
              label="Test member No."
              name="members"
              rules={[
                {
                  validator: (rule: any, value: string) => {
                    if (!value) return Promise.resolve();

                    const isValid = /^[a-zA-Z0-9]{1,8}$/.test(value);

                    return isValid
                      ? Promise.resolve()
                      : Promise.reject(
                          "Please enter the member number in the correct format."
                        );
                  },
                },
              ]}
            >
              <Input
                placeholder="Enter the member number for testing"
                onChange={(e) => setTestId(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 8, span: 12 }}
              style={{ marginTop: "-24px" }}
            >
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginTop: 24 }}
                loading={testLoading}
                disabled={!testId}
              >
                Submit to test
              </Button>
            </Form.Item>
            {!id && (
              <MainContentFooter
                isLoading={loading}
                disabled={testId !== ""}
                testId={testId}
                backTo="/club-app/push-notification"
              />
            )}
          </Form>
        )}
      </ContentSection>
    </div>
  );
};

export default ManualNotificationDetail;
